import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'join',
	standalone: true
})
export class JoinPipe implements PipeTransform {
	transform(value: string[] | number[] | readonly string[] | readonly number[], joiner = '&#013;&#010;'): string {
		return value?.join(joiner) ?? '';
	}
}
